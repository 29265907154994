import { Box, Button, Text } from "grommet";

import { messagesEspaceCandidat } from "../../messages/messagesEspaceCandidat";
import { VerticalDivider } from "../components/VerticalDivider";
import { Logo } from "../Logo";
import { LocaleDate } from "../components/LocaleDate";
import { getTheme } from "../etapeThemes";
import { useEffect, useState } from "react";
import { paths } from "../../paths";
import { downloadUrl } from "../downloadUrl";

export const Convocation = (props) => {
    const [convocationUrl, setConvocationUrl] = useState(undefined);

    const convocation = (() => {
        let temp;
        switch (props.etape.etat) {
            case "encours":
                temp = getTheme(
                    "ok",
                    messagesEspaceCandidat.CONVOCATION_RECUE,
                    props.etape.epreuves?.length > 0 &&
                        props.etape.epreuves[0].debutEpreuve ? (
                        <LocaleDate
                            date={props.etape.epreuves[0].debutEpreuve}
                            color="sicmiorange"
                            locale="fr-FR"
                            textPrefix={
                                props.hasNextEpreuve ? messagesEspaceCandidat.CONVOCATION_RECUE_BODY : messagesEspaceCandidat.CONVOCATION_TERMINEE
                            }
                        />
                    ) : (
                        props.hasNextEpreuve ? messagesEspaceCandidat.CONVOCATION_RECUE_BODY_NO_DATE : messagesEspaceCandidat.CONVOCATION_TERMINEE_NO_DATE
                    )
                );
                temp.convoc_body_color = "sicmiorange";
                return temp;
            case "close":
                temp = getTheme(
                    "doneOK",
                    messagesEspaceCandidat.CONVOCATION_RECUE,
                    props.etape.convocationDate ? (
                    <LocaleDate
                        date={props.etape.convocationDate}
                        color="sicmiblack"
                        locale="fr-FR"
                        textPrefix={messagesEspaceCandidat.CONVOCATION_TERMINEE}
                    />) : (messagesEspaceCandidat.CONVOCATION_TERMINEE_NO_DATE)
                );
                temp.convoc_body_color = "black";
                return temp;
            default:
                return getTheme("pending", undefined, undefined);
        }
    })();

    useEffect(() => {
        if (props.etape?.convocationDate && props.idDossier) {
            const params = {
                idSession: props.idSession,
                idEtape: props.etape?.idEtape,
                idDossier: props.idDossier,
            };
            setConvocationUrl(
                downloadUrl(paths.API_CANDIDAT_CONVOCATION, params)
            );
        }
    }, [props.etape, props.idDossier, props.idSession]);

    function downloadConvocation() {
        window.open(convocationUrl, "_blank").focus();
    }

    return (
        <Box direction="row" gap="small" fill="horizontal">
            <Box direction="column" width={{ min: "xxsmall", max: "xxsmall" }}>
                <Logo
                    background={convocation.theme.backgroundColor}
                    border={convocation.theme.border}
                >
                    {convocation.theme.logo}
                </Logo>
                <VerticalDivider
                    background={{
                        color: convocation.theme.dividerColor,
                        doted: convocation.theme.doted,
                    }}
                />
            </Box>
            <Box direction="column" gap="small" fill="horizontal">
                <Box height={{ min: "48px" }} justify="center">
                    <LocaleDate
                        date={props.etape.convocationDate}
                        color="sicmicontrastygrey"
                        locale="fr-FR"
                    />
                    <Text weight="bold" size="large" color="sicmiblack">
                        {convocation.header}
                    </Text>
                </Box>

                <Box
                    border={{ size: "medium" }}
                    round="small"
                    margin={{ bottom: "small" }}
                >
                    <Box pad="xsmall" round="small" background="sicmiwhite">
                        <Box margin="xsmall" gap="small">
                            <Box>
                                <Text
                                    color={convocation.convoc_body_color}
                                    weight="bold"
                                >
                                    {convocation.body}
                                </Text>
                            </Box>
                            {convocationUrl && (
                                <Box align="end">
                                    <Button
                                        a11yTitle={
                                            messagesEspaceCandidat.DOWNLOAD_CONVOCATION_ARIA
                                        }
                                        color="sicmiblack"
                                        label={
                                            messagesEspaceCandidat.DOWNLOAD_CONVOCATION
                                        }
                                        onClick={() => downloadConvocation()}
                                    />
                                </Box>
                            )}
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};
