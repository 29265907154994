import { useEffect, useState } from "react";
import { Box, Button, Form, Grommet, Heading, Layer, Main, MaskedInput, Text } from "grommet";
import { StatusGood } from "grommet-icons";
import { useHistory } from "react-router-dom";

import { messagesEspaceCandidat } from "../messages/messagesEspaceCandidat";
import { messagesCommon } from "../messages/messagesCommon";
import { HeaderSicmi } from "../common/HeaderSicmi";
import { FooterSicmi } from "../common/FooterSicmi";
import { theme } from "../theme";
import { paths } from "../paths";
import { store } from "../auth/store";
import { agentCandidat } from "./agentCandidat";
import { EmailFormField } from "../inscription/inputs/EmailFormField";
import { TextFormField } from "../inscription/inputs/TextFormField";
import { Toast } from "../common/Toast";
import { ModalWarning } from "../common/ModalWarning";

export const CreationCompte = (props) => {
    const [success, setSuccess] = useState(false);
    const [warning, setWarning] = useState(false);
    const [toastEmailErrorCount, setToastEmailErrorCount] = useState(0);
    const [toastFormErrorCount, setToastFormErrorCount] = useState(0);
    const [formValue, setFormValue] = useState(null);

    const history = useHistory();

    const nameRegex = "[a-zA-Z\u00C0-\u00FF -]";
    const passwordRegex = "[^\\`'\" ]";

    const breadcrumbs = [
        { label: "Accueil", href: paths.HOME },
        { label: messagesEspaceCandidat.CREATION_COMPTE },
    ];

    useEffect(() => {
        document.title = messagesCommon.CREATE_ACCOUNT + " – " + messagesCommon.MAIN_PAGE_TITLE;
    }, []);

    const onSubmit = (e) => {
        if(e.value.nom.trim() === e.value.prenom.trim()){
            setFormValue(e.value);
            setWarning(true);
        } else {
            creerCompte(e.value);
        }
        
    };

    const creerCompte = (value) => {
        agentCandidat.creerCompte(value, (result) => {
            if (result.ok) {
                setSuccess(true);
            } else if (result.status === 409) {
                setToastEmailErrorCount(toastEmailErrorCount + 1);
            } else if (result.status === 415) {
                setToastFormErrorCount(toastFormErrorCount + 1);
            }
        });
    };

    const onDismiss = (e) => {
        history.push(paths.HOME);
    };

    const nameValidation = (length) => {
        return {
            regexp: new RegExp("^" + nameRegex + "{" + length + ",}$"),
            message:
                length +
                " caractères minimum",
        };
    };

    const passwordValidation = (length) => {
        return {
            regexp: new RegExp("^" + passwordRegex + "{" + length + ",}$"),
            message: length + " caractères minimum (\\ ' \" ` et espace sont interdits)",
        };
    };

    const closeModal = (isValided: boolean) => {
        setWarning(false);
        if(isValided){
            creerCompte(formValue);
        }
    };

    return (
        <Grommet full theme={theme}>
            <HeaderSicmi
                title={messagesEspaceCandidat.CREATION_COMPTE}
                breadcrumbs={breadcrumbs}
            />
            <Toast
                count={toastEmailErrorCount}
                status="status-error"
                text={messagesEspaceCandidat.EMAIL_PRIS}
            />
            <Toast
                count={toastFormErrorCount}
                status="status-error"
                text={messagesEspaceCandidat.FORM_INVALIDE}
            />
            <Main role="main" align="center">
                <Box
                    direction="column"
                    pad={{ top: "small", bottom: "small" }}
                    margin={{ top: "medium", bottom: "medium" }}
                    gap="small"
                    width="60%"
                >
                    {!store.connected && (
                        <>
                            <Text color="sicmired" size="small">
                                * : Champs obligatoires
                            </Text>
                            <Form
                                messages={{ required: "Requis" }}
                                validate="blur"
                                onSubmit={onSubmit}
                            >
                                <EmailFormField
                                    label="Email"
                                    required
                                    name="email"
                                />
                                <TextFormField
                                    label="Nom"
                                    required
                                    name="nom"
                                    validate={nameValidation(2)}
                                >
                                    <MaskedInput
                                        mask={[
                                            {
                                                regexp: new RegExp("^" + nameRegex + "*$"),
                                            },
                                        ]}
                                        name="nom"
                                        id="nom"
                                    />
                                </TextFormField>
                                <TextFormField
                                    label="Prénom"
                                    required
                                    name="prenom"
                                    validate={nameValidation(2)}
                                >
                                    <MaskedInput
                                        mask={[
                                            {
                                                regexp: new RegExp("^" + nameRegex + "*$"),
                                            },
                                        ]}
                                        name="prenom"
                                        id="prenom"
                                    />
                                </TextFormField>
                                <TextFormField
                                    label="Mot de passe"
                                    required
                                    name="password"
                                    id="password"
                                    type="password"
                                    validate={passwordValidation(6)}
                                />
                                <Box align="center">
                                    <Button
                                        type="submit"
                                        label="Créer mon espace candidat"
                                        primary
                                        margin={{ top: "small" }}
                                    />
                                </Box>
                            </Form>

                            {success && (
                                <Layer
                                    position="center"
                                    role="dialog"
                                    aria-modal="true"
                                    tabIndex={-1}
                                    onEsc={onDismiss}
                                    aria-labelledby="creationOkTitle"
                                >
                                    <Box
                                        pad="medium"
                                        gap="small"
                                        width="medium"
                                        align="center"
                                    >
                                        <Box
                                            direction="row"
                                            gap="small"
                                            align="center"
                                        >
                                            <StatusGood color="green" />
                                            <Heading level={3} margin="none" id="creationOkTitle">
                                                {
                                                    messagesEspaceCandidat.CREATION_SUCCES_TITLE
                                                }
                                            </Heading>
                                        </Box>
                                        <Text>
                                            {
                                                messagesEspaceCandidat.CREATION_SUCCES_BODY
                                            }
                                        </Text>

                                        <Box
                                            as="footer"
                                            gap="small"
                                            direction="row"
                                            align="center"
                                            justify="between"
                                            pad={{
                                                top: "medium",
                                                bottom: "small",
                                            }}
                                        >
                                            <Button
                                                label={
                                                    <Text color="white">
                                                        <strong>
                                                            {
                                                                messagesCommon.BACK_HOME
                                                            }
                                                        </strong>
                                                    </Text>
                                                }
                                                onClick={onDismiss}
                                                primary
                                            />
                                        </Box>
                                    </Box>
                                </Layer>
                            )}
                        </>
                    )}
                    {store.connected && messagesCommon.ERROR_AUTHENTIFIED}
                
                    {warning && 
                    <ModalWarning message={messagesCommon.ALERT_LASTNAME_FIRSTNAME} onClose={closeModal} />
                    }
                </Box>
            </Main>
            <FooterSicmi />
        </Grommet>
    );
};
