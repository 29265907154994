import { Box, Tab, Tabs, Text } from "grommet";
import { messagesInscriptionSession } from "../messages/messagesInscriptionSession";

export const FormulaireTabs = (props: any) => {
    return (
        <Box gap="small">
            <Tabs justify="start" onActive={props.onTabChange}>
                <Tab
                    a11yTitle={
                        props.autresRensValide
                            ? messagesInscriptionSession.ETAT_CIVIL
                            : messagesInscriptionSession.ETAT_CIVIL +
                              messagesInscriptionSession.ONGLET_ERREUR
                    }
                    title={
                        props.etatCivilValide ? (
                            messagesInscriptionSession.ETAT_CIVIL
                        ) : (
                            <Text color="sicmired">
                                {messagesInscriptionSession.ETAT_CIVIL +
                                    messagesInscriptionSession.ONGLET_ERREUR}
                            </Text>
                        )
                    }
                />
                <Tab
                    a11yTitle={
                        props.autresRensValide
                            ? messagesInscriptionSession.AUTRE_RENS
                            : messagesInscriptionSession.AUTRE_RENS +
                              messagesInscriptionSession.ONGLET_ERREUR
                    }
                    title={
                        props.autresRensValide ? (
                            messagesInscriptionSession.AUTRE_RENS
                        ) : (
                            <Text color="sicmired">
                                {messagesInscriptionSession.AUTRE_RENS +
                                    messagesInscriptionSession.ONGLET_ERREUR}
                            </Text>
                        )
                    }
                    style={{
                        display: props.displayAutresRens ? "flex" : "none",
                    }}
                />
                <Tab
                    title={messagesInscriptionSession.COMPETENCES}
                    style={{
                        display: "none",
                    }}
                />
                <Tab
                    a11yTitle={
                        props.autresRensValide
                            ? messagesInscriptionSession.OPTIONS
                            : messagesInscriptionSession.OPTIONS +
                              messagesInscriptionSession.ONGLET_ERREUR
                    }
                    title={
                        props.optionsValide ? (
                            messagesInscriptionSession.OPTIONS
                        ) : (
                            <Text color="sicmired">
                                {messagesInscriptionSession.OPTIONS +
                                    messagesInscriptionSession.ONGLET_ERREUR}
                            </Text>
                        )
                    }
                    style={{
                        display: props.displayOptions ? "flex" : "none",
                    }}
                />
                <Tab
                    a11yTitle={
                        props.autresRensValide
                            ? messagesInscriptionSession.PIECES_A_JOINDRE
                            : messagesInscriptionSession.PIECES_A_JOINDRE +
                              messagesInscriptionSession.ONGLET_ERREUR
                    }
                    title={
                        props.piecesJointesValide ? (
                            messagesInscriptionSession.PIECES_A_JOINDRE
                        ) : (
                            <Text color="sicmired">
                                {messagesInscriptionSession.PIECES_A_JOINDRE +
                                    messagesInscriptionSession.ONGLET_ERREUR}
                            </Text>
                        )
                    }
                    style={{
                        display: props.displayPiecesJointes ? "flex" : "none",
                    }}
                />
            </Tabs>
        </Box>
    );
};
