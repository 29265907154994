import { Box, Text } from "grommet";

import { RoutedAnchor } from "../anchor/RoutedAnchor";
import { messagesCommon } from "../messages/messagesCommon";

export const BreadCrumbs = (props) => {
    return (
        <Box
            as="nav"
            role="navigation"
            title={messagesCommon.BREADCRUMBS}
            a11yTitle={messagesCommon.BREADCRUMBS}
            direction="row"
            gap="small"
        >
            {props.items.map((item, i) =>
                props.items[i + 1] ? (
                    <Box direction="row" gap="small" key={i}>
                        <RoutedAnchor to={item.href} label={item.label} />
                        <Text weight="bold">{">"}</Text>
                    </Box>
                ) : (
                    <Box direction="row" gap="small" key={i}>
                        <Text weight="bold">{item.label}</Text>
                    </Box>
                )
            )}
        </Box>
    );
};
