import { Box, FormField, RadioButtonGroup, Text } from "grommet";

import { messagesEtatCivil } from "../messages/messagesEtatCivil";
import { TextFormField } from "./inputs/TextFormField";
import { ListFormField } from "./inputs/ListFormField";
import { PhoneFormField } from "./inputs/PhoneFormField";
import { useResponsive, useScreenSize } from "../responsive";
import "./inscription.css";
import { MaskedTextFormField } from "./inputs/MaskedTextFormField";
import { messagesCommon } from "../messages/messagesCommon";
import { store } from "../auth/store";
import { PostalCodeFormField } from "./inputs/PostalCodeFormField";
import { DateFormField } from "./inputs/DateFormField";
import { formCodes } from "./formCodes";

export const EtatCivil = (props) => {
    const smallTheme = {
        border: "top",
    };

    const regularTheme = {
        border: true,
    };
    const screenSize = useScreenSize();
    const responsiveTheme = useResponsive(smallTheme, regularTheme);

    return (
        <>
            <Box
                border={responsiveTheme.border}
                direction="row"
                pad="small"
                justify="between"
            >
                {screenSize !== "small" && (
                    <>
                        <Box direction="column" width="40%">
                            <ColumnOne data={props} />
                        </Box>
                        <Box direction="column" width="40%">
                            <ColumnTwo data={props} />
                        </Box>
                    </>
                )}
                {screenSize === "small" && (
                    <>
                        <Box
                            direction="column"
                            width="100%"
                            margin={{ bottom: "xlarge" }}
                        >
                            <ColumnOne data={props} />
                            <ColumnTwo data={props} />
                        </Box>
                    </>
                )}
            </Box>
        </>
    );
};

const ColumnOne = (props) => {
    const data = props.data;
    return (
        <>
            <TextFormField
                name={formCodes.CIVILITE}
                label={messagesEtatCivil.CIVILITE}
                required={true}
            >
                <RadioButtonGroup
                    name={formCodes.CIVILITE}
                    id={formCodes.CIVILITE}
                    direction="row"
                    options={[
                        { label: "M", value: "M" },
                        { label: "Mme", value: "Mme" },
                    ]}
                    a11yTitle={messagesEtatCivil.CIVILITE_ARIA}
                    title={messagesEtatCivil.CIVILITE_ARIA}
                />
            </TextFormField>
            <MaskedTextFormField
                name={formCodes.NOM}
                label={messagesEtatCivil.NOM}
                required={true}
                requiredForSave={true}
                maxLength={70}
                regexRule="S1"
            />
            <MaskedTextFormField
                name={formCodes.NOM_MARITAL}
                label={messagesEtatCivil.NOM_MARITAL}
                required={false}
                maxLength={70}
                regexRule="S1"
            />
            <MaskedTextFormField
                name={formCodes.PRENOM}
                label={messagesEtatCivil.PRENOM}
                required={true}
                requiredForSave={true}
                maxLength={70}
                regexRule="S1"
            />
            <MaskedTextFormField
                name={formCodes.AUTRES_PRENOMS}
                label={messagesEtatCivil.AUTRE_PRENOMS}
                required={false}
                maxLength={70}
                regexRule="S1"
            />
            <MaskedTextFormField
                name={formCodes.ADRESSE_1}
                label={messagesEtatCivil.ADRESSE}
                margin={{ top: "xlarge" }}
                required={true}
                maxLength={130}
                regexRule="S2"
            />
            <MaskedTextFormField
                name={formCodes.ADRESSE_2}
                label={messagesEtatCivil.COMP_ADRESSE}
                maxLength={130}
                regexRule="S2"
            />
            {data.formulaire?.pays?.code === "033" && (
                <MaskedTextFormField
                    name={formCodes.COMMUNE}
                    label={messagesEtatCivil.COMMUNE}
                    required={true}
                    regexRule="S2"
                />
            )}
            {data.formulaire?.pays?.code === "033" && (
                <PostalCodeFormField
                    name={formCodes.CODE_POSTAL}
                    label={messagesEtatCivil.CP}
                    required={true}
                    maxLength={5}
                    requiredLength={5}
                    a11yTitle={messagesEtatCivil.CP}
                    departements={data.listeDepartements}
                    couverture={data.couverture}
                />
            )}

            <ListFormField
                name={formCodes.PAYS}
                label={messagesEtatCivil.PAYS}
                required={true}
                options={data.listePays}
                labelKey="libelle"
                valueKey="trefPaysId"
                info={
                    data.formulaire.pays &&
                    data.formulaire?.pays?.code !== "033"
                        ? data.labels.info_pays_nofrance
                        : undefined
                }
            />
        </>
    );
};

const ColumnTwo = (props) => {
    const data = props.data;

    function getListePaysNoAcquisition() {
        return data.listePays.filter(p => p.code === "033");
    }

    function checkBirthDate(dateNaiss) {
        if (
            data.conditions?.ageMin !== undefined &&
            data.conditions?.ageMax !== undefined
        ) {
            let date = new Date(dateNaiss);
            let ageDiff = Date.now() - date.getTime();
            let ageDate = new Date(ageDiff); // miliseconds from epoch
            let age = Math.abs(ageDate.getUTCFullYear() - 1970);

            // Pour éviter d'afficher à la fois l'erreur bloquante et le warning
            // Donc si erreur bloquante, on ne retourne rien
            if (
                (data.paramSaisie?.ageMin > 0 &&
                    age < data.paramSaisie?.ageMin) ||
                (data.paramSaisie?.ageMax > 0 && age > data.paramSaisie?.ageMax)
            ) {
                return undefined;
            }

            if (
                (data.conditions.ageMin > 0 && age < data.conditions.ageMin) ||
                (data.conditions.ageMax > 0 && age > data.conditions.ageMax)
            ) {
                let warning = data.labels?.conditionAge;
                if (warning) {
                    warning = (
                        <Text weight="bold" color="sicmiorange">
                            {warning
                                .replace("AGE_MIN", data.conditions.ageMin)
                                .replace("AGE_MAX", data.conditions.ageMax)}
                        </Text>
                    );
                }
                return warning;
            }
        }
        return undefined;
    }

    return (
        <>
            <DateFormField
                id="dateNaissFormField"
                name={formCodes.DATE_NAISSANCE}
                label={messagesEtatCivil.DATE_NAISS}
                required={true}
                placeholder="jj/mm/aaaa"
                birthdate
                ageMin={data.paramSaisie?.ageMin}
                ageMax={data.paramSaisie?.ageMax}
                info={
                    data.formulaire[formCodes.DATE_NAISSANCE]
                        ? checkBirthDate(
                              data.formulaire[formCodes.DATE_NAISSANCE]
                          )
                        : undefined
                }
            />
            <MaskedTextFormField
                name={formCodes.LIEU_NAISSANCE}
                label={messagesEtatCivil.LIEU_NAISS}
                required={true}
                maxLength={70}
                regexRule="S1"
            />
            <ListFormField
                name={formCodes.NATIONALITE}
                label={messagesEtatCivil.NATIONALITE}
                options={data.noAcquisition === 1 ? getListePaysNoAcquisition() : data.listePays}
                required={true}
                labelKey="libelle"
                valueKey="trefPaysId"
            />
            {!data.formulaire?.nationalite ||
                (data.formulaire?.nationalite?.code !== "033" && (
                    <ListFormField
                        name={formCodes.NATIONALITE_EN_COURS}
                        label={messagesEtatCivil.NATIONALITE_EN_COURS_ACQUI}
                        options={data.listePaysAcquisition}
                        required={true}
                        labelKey="libelle"
                        valueKey="trefPaysId"
                    />
                ))}
            <ListFormField
                name={formCodes.PAYS_NAISSANCE}
                label={messagesEtatCivil.PAYS_NAISS}
                required={true}
                options={data.listePays}
                labelKey="libelle"
                valueKey="trefPaysId"
            />
            {data.formulaire?.paysNaiss?.code === "033" && (
                <ListFormField
                    name={formCodes.DEPARTEMENT_NAISSANCE}
                    label={messagesEtatCivil.DEP_NAISS}
                    required={true}
                    options={data.listeDepartements}
                    labelKey="libelle"
                    valueKey="trefDepartementId"
                />
            )}
            {data.formulaire?.paysNaiss?.code !== "033" && (
                <Box margin={{ top: "xlarge" }} />
            )}

            <FormField
                margin={{ top: "xlarge" }}
                info="Pour changer votre adresse email, rendez-vous dans votre espace candidat"
                name={formCodes.EMAIL}
                label={messagesCommon.EMAIL}
                value={store.email}
                disabled
            />
            <PhoneFormField
                name={formCodes.TELEPHONE}
                label={messagesEtatCivil.TEL}
                required={true}
            />
        </>
    );
};
