import { FormField, RadioButtonGroup } from "grommet";

import { FormFieldLabel } from "./FormFieldLabel";

export const RadioFormField = (props) => {
    const { label, required, name, italicLabel, options, ...rest } = props;
    return (
        <FormField
            label={
                <FormFieldLabel
                    label={label}
                    required={required}
                    italicLabel={italicLabel}
                />
            }
            name={name}
            required={required}
            {...rest}
        >
            <RadioButtonGroup
                a11yTitle={label}
                title={label}
                name={name}
                options={options}
                {...rest}
            />
        </FormField>
    );
};
