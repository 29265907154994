import { FormField, Select } from "grommet";

import { messagesCommon } from "../../messages/messagesCommon";
import { FormFieldLabel } from "./FormFieldLabel";

export const ListFormField = (props) => {
    const { label, required, name, options, labelKey, valueKey, italicLabel, onChange, clear, disabled, ...rest } = props;

    return (
        <FormField
            label={<FormFieldLabel label={label} required={required} italicLabel={italicLabel} />}
            required={required}
            name={name}
            htmlFor={name}
            {...rest}
        >
            <Select
                name={name}
                id={name}
                placeholder={messagesCommon.SELECT}
                labelKey={labelKey}
                valueKey={valueKey}
                options={options}
                a11yTitle={label}
                replace
                focusIndicator
                plain
                clear={clear !== false ? { label: '(vide)' } : false}
                disabled={disabled}
            />
        </FormField>
    );
};
