import { Anchor, Box, Footer } from "grommet";
import { Cluster, DocumentText, Logout, Mail } from "grommet-icons";

import { RoutedAnchor } from "../anchor/RoutedAnchor";
import { messagesCommon } from "../messages/messagesCommon";
import { paths } from "../paths";
import { useResponsive } from "../responsive";

export const FooterSicmi = () => {
    const smallTheme = {
        footerGap: "small",
        justify: "center",
        containerPadding: {vertical:"medium", horizontal:"large"},
        fill: "horizontal",
        round: false
    };

    const regularTheme = {
        footerGap: "large",
        containerPadding: "small",
        footerPadding: "small",
        fill: false,
        round: "small",
    };

    const responsiveTheme = useResponsive(smallTheme, regularTheme);
    return (
        <Footer
            role="contentinfo"
            as="footer"
            background="none"
            pad={responsiveTheme.footerPadding}
            justify="center"
        >
            <Box
                pad={responsiveTheme.containerPadding}
                gap={responsiveTheme.footerGap}
                direction="row-responsive"
                background={{ color: "light-5" }}
                round={responsiveTheme.round}
                fill={responsiveTheme.fill}
                elevation="xlarge"
            >
                <Box
                    direction="row"
                    gap="xsmall"
                    justify={responsiveTheme.justify}
                >
                    <DocumentText role="img" color="black" />
                    <RoutedAnchor
                        color="black"
                        to={paths.ACCESSIBILITE}
                        label={messagesCommon.ACCESSIBILITE_FOOTER}
                    />
                </Box>
                <Box
                    direction="row"
                    gap="xsmall"
                    justify={responsiveTheme.justify}
                >
                    <Cluster role="img" color="black" />
                    <RoutedAnchor
                        color="black"
                        to={paths.SITE_MAP}
                        label={messagesCommon.SITE_MAP}
                    />
                </Box>
                <Box
                    direction="row"
                    gap="xsmall"
                    justify={responsiveTheme.justify}
                >
                    <Mail role="img" color="black" />
                    <RoutedAnchor
                        to={paths.CONTACT}
                        color="black"
                        label={messagesCommon.CONTACT}
                    />
                </Box>
                <Box
                    direction="row"
                    gap="xsmall"
                    justify={responsiveTheme.justify}
                >
                    <Logout role="img" color="black" />
                    <Anchor
                        href={paths.URL_MININT}
                        color="black"
                        label={messagesCommon.SITE_MINISTERE}
                    />
                </Box>
            </Box>
        </Footer>
    );
};
