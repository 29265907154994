import { Box, Grommet } from "grommet";

import { HeaderSicmi } from "../common/HeaderSicmi";
import { FooterSicmi } from "../common/FooterSicmi";
import { theme } from "../theme";
import { ErrorBody } from "./ErrorBody";
import { useEffect } from "react";
import { messagesCommon } from "../messages/messagesCommon";

export const ErrorPage = (props) => {

    useEffect(() => {
        document.title = messagesCommon.ERROR + " – " + messagesCommon.MAIN_PAGE_TITLE;
    }, []);
    
    return (
        <Grommet full theme={theme}>
            <Box fill>
                <HeaderSicmi
                />
                <ErrorBody text={props.text} returnPath={props.returnPath} returnLabel={props.returnLabel} />
                <FooterSicmi />
            </Box>
        </Grommet>
    );
};
