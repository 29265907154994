import { Anchor, Box, Button, FileInput, Text } from "grommet";

import { messagesEspaceCandidat } from "../../messages/messagesEspaceCandidat";
import { messagesCommon } from "../../messages/messagesCommon";
import { CaretRightFill } from "grommet-icons";
import { paths } from "../../paths";
import { useEffect, useState } from "react";
import { agentCandidat } from "../agentCandidat";
import { config } from "../../config";
import { downloadUrl } from "../downloadUrl";

export const PieceAdministrative = (props) => {
    const [exempleUrl, setExempleUrl] = useState(undefined);
    const [file, setFile] = useState(undefined);
    const [documentPresent, setDocumentPresent] = useState(false);
    const [documentUrl, setDocumentUrl] = useState(undefined);
    const [loading, setLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState(undefined);

    useEffect(() => {
        if (props.piece) {
            if (
                props.piece.presenceExemple &&
                !(documentPresent || props.piece.presenceDocument)
            ) {
                setExempleUrl(
                    downloadUrl(paths.API_PIECE_ADMIN_EXEMPLE, {
                        idSession: props.idSession,
                        nomPiece: props.piece?.libellePiece,
                    })
                );
            }

            if (
                (documentPresent || props.piece.presenceDocument)
            ) {
                setDocumentUrl(
                    downloadUrl(paths.API_PIECE_ADMIN_DOCUMENT, {
                        idSession: props.idSession,
                        nomPiece: props.piece?.libellePiece,
                        idCandidat: props.piece.candidatId,
                    })
                );
            }
        }
    }, [props, documentPresent]);

    function getFileSizeError() {
        return messagesEspaceCandidat.PIECE_ADMIN_MAXSIZE_ERROR.replace(
            "$size",
            String(config.PIECE_ADMIN_MAXSIZE_BYTES / 1000000)
        );
    }

    function onFileUpload() {
        const myFile: File = file;
        if (
            myFile &&
            myFile.size > 0 &&
            myFile.size < config.PIECE_ADMIN_MAXSIZE_BYTES
        ) {
            setLoading(true);
            setErrorMsg(undefined);
            let formData = new FormData();
            formData.append("file", myFile);
            formData.append("nomPiece", props.piece.libellePiece);
            formData.append("idSession", props.idSession);
            formData.append("idCandidat", props.piece.candidatId);
            agentCandidat.uploadPieces(formData, (data) => {
                setLoading(false);
                if (data === true) {
                    setExempleUrl(undefined);
                    setDocumentPresent(true);
                } else if (data === "401") {
                    setErrorMsg(messagesCommon.ERROR_401);
                } else if (data === "412") {
                    setErrorMsg(
                        messagesEspaceCandidat.PIECE_ADMIN_FILE_REJECTED
                    );
                } else {
                    setErrorMsg(messagesCommon.BACKEND_ERROR);
                }
            });
        } else if (myFile.size > config.PIECE_ADMIN_MAXSIZE_BYTES) {
            setErrorMsg(getFileSizeError());
        }
    }

    return (
        <Box gap="small">
            <Box align="center" direction="row" gap="xlarge">
                <Box align="center" direction="row">
                    <CaretRightFill />
                    <Text weight="bold" size="large" color="sicmiblack">
                        {props.piece?.libellePiece}
                    </Text>
                </Box>
                {exempleUrl && (
                    <Anchor
                        target="_blank"
                        href={exempleUrl}
                        label={messagesEspaceCandidat.CONSULTER_MODELE}
                    />
                )}
                {documentUrl && (
                    <Anchor
                        target="_blank"
                        href={documentUrl}
                        label={messagesEspaceCandidat.CONSULTER_DOCUMENT}
                    />
                )}
            </Box>

            {props.piece && !(props.piece.presenceDocument || documentPresent) && (
                <Box pad={{ left: "medium" }} direction="row-responsive" gap="small">
                    <FileInput
                        multiple={false}
                        messages={messagesCommon.FILE_INPUT_MSG}
                        accept={config.ALLOWED_FILE_TYPES}
                        maxSize={config.PIECE_ADMIN_MAXSIZE_BYTES}
                        title={messagesEspaceCandidat.DEMANDE_PIECE_ADMIN_INPUT}
                        aria-label={messagesEspaceCandidat.DEMANDE_PIECE_ADMIN_INPUT}
                        onChange={(event) => {
                            if (
                                (event.target as HTMLInputElement).files &&
                                (event.target as HTMLInputElement).files.length
                            ) {
                                const file: File = event.target.files[0];
                                if (
                                    file.size > config.PIECE_ADMIN_MAXSIZE_BYTES
                                ) {
                                    setErrorMsg(getFileSizeError());
                                } else {
                                    setErrorMsg(undefined);
                                }
                                setFile(file);
                            } else {
                                setFile(undefined);
                                setErrorMsg(undefined);
                            }
                        }}
                    />
                    <Button
                        color="sicmiblack"
                        disabled={loading}
                        label={messagesEspaceCandidat.DEMANDE_PIECE_ADMIN_SEND}
                        onClick={() => onFileUpload()}
                    />
                </Box>
            )}
            {errorMsg && (
                <Box pad={{ left: "medium" }}>
                    <Text color="status-critical" weight="bold">
                        {errorMsg}
                    </Text>
                </Box>
            )}
        </Box>
    );
};
