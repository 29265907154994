import { useEffect, useState } from "react";
import { Box, Grommet, Text } from "grommet";
import { useHistory, useLocation } from "react-router-dom";
import { StatusCritical, StatusGood } from "grommet-icons";

import { messagesEspaceCandidat } from "../messages/messagesEspaceCandidat";
import { HeaderSicmi } from "../common/HeaderSicmi";
import { FooterSicmi } from "../common/FooterSicmi";
import { theme } from "../theme";
import { paths } from "../paths";
import { agentCandidat } from "./agentCandidat";
import { messagesCommon } from "../messages/messagesCommon";

export const ConfirmationRattachementEmail = () => {
    const [done, setDone] = useState(false);
    const [email, setEmail] = useState();
    const [error, setError] = useState(false);
    const [errorMsg, setErrorMsg] = useState();

    const urlParams = new URLSearchParams(useLocation().search);
    const history = useHistory();
    const token = urlParams.get("token");

    const breadcrumbs = [
        { label: "Accueil", href: paths.HOME },
        { label: "Rattachement de compte" },
    ];

    useEffect(() => {
        if (!token) {
            history.push(paths.HOME);
        } else {
            agentCandidat.confirmerRattachementEmail(token, (result) => {

                if (result.result === true) {
                    setDone(true);
                    setEmail(result.email);
                } else {
                    setError(true);
                    setErrorMsg(result.errorMsg);
                }
            });
        }
    }, [token, setDone, setError]);

    useEffect(() => {
        document.title = messagesCommon.CONFIRM_RATTACHEMENT + " – " + messagesCommon.MAIN_PAGE_TITLE;
    }, []);

    return (
        <Grommet full theme={theme}>
            <Box overflow="auto">
                <HeaderSicmi
                    title={messagesEspaceCandidat.PAGE_TITLE}
                    breadcrumbs={breadcrumbs}
                />

                <Box
                    as="main"
                    role="main"
                    direction="column"
                    fill="horizontal"
                    pad={{ top: "xlarge" }}
                    flex={false}
                    align="center"
                    margin={{ bottom: "xlarge" }}
                >
                    {(done === false && error === false) && (
                        <Box
                            align="center"
                            justify="center"
                            direction="row"
                            gap="small"
                        >
                            <Box
                                direction="row"
                                border={[
                                    {
                                        side: "all",
                                        color: "transparent",
                                        size: "medium",
                                    },
                                    {
                                        side: "horizontal",
                                        color: "brand",
                                        size: "medium",
                                    },
                                ]}
                                pad="small"
                                round="full"
                                animation={{
                                    type: "rotateRight",
                                    duration: 1500,
                                }}
                            />
                            <Text weight="bold">
                                Rattachement de compte en cours
                            </Text>
                        </Box>
                    )}
                    {(done === true && error === false) && (
                    <Box gap="medium" align="center">
                        <StatusGood size="xlarge" color="limegreen" />
                        <Text size="large" weight="bold">{`Le ou les anciens comptes liés à l'email ${email} ont été rattachés à votre compte actuel.`}</Text>
                    </Box>
                    )}
                    {error === true && (
                    <Box gap="medium" align="center">
                        <StatusCritical size="xlarge" color="sicmired" />
                        <Text size="large" weight="bold">{errorMsg}</Text>
                    </Box>
                    )}
                </Box>
            </Box>
            <FooterSicmi />
        </Grommet>
    );
};
