export const messagesAccessibilite = {
    ACCESSIBILITY_TITLE: "Déclaration d'accessibilité",
    ACCESSIBILITY_BODY_1: "Le ministère de l'intérieur s'engage à rendre ses sites internet accessibles conformément à l'article 47 de la loi n° 2005-102 du 11 février 2005.",
    ACCESSIBILITY_BODY_2: "À cette fin, il met en œuvre la stratégie et les actions suivantes : ",
    ACCESSIBILITY_BODY_3: "Cette déclaration d'accessibilité s'applique à www.inscription-concours.interieur.gouv.fr.",
    ACCESSIBILITY_LINK_1: "Schéma pluriannuel accessibilité du ministère de l'Intérieur et des Outre-mer 2022-2024 - Accessible",
    ACCESSIBILITY_LINK_2: "Plan annuel d'accessibilité - 2023 - Accessible ",
    ACCESSIBILITY_LINK_3: "Plan annuel d'accessibilite 2022.pdf",
    ETAT_CONFORMITE_TITLE: "État de conformité",
    ETAT_CONFORMITE_BODY_1: "https://www.inscription-concours.interieur.gouv.fr n'est pas en conformité ",
    ETAT_CONFORMITE_BODY_2: "avec le référentiel général d'amélioration de l'accessibilité ",
    ETAT_CONFORMITE_BODY_3: "RGAA version 4.1.2",
    ETAT_CONFORMITE_BODY_4: ". Les non-conformités et les éventuelles dérogations sont énumérées ci-dessous.",
    RESULTAT_TESTS_TITLE: "Résultat des tests",
    RESULTAT_TESTS_BODY_1: "L'audit de conformité réalisé par la société ",
    RESULTAT_TESTS_BODY_SOCIETE: "Empreinte Digitale",
    RESULTAT_TESTS_BODY_2: " révèle que :",
    RESULTAT_TESTS_LIST_1_POURCENT: "44.82%",
    RESULTAT_TESTS_LIST_1: " des critères RGAA sont respectés.",
    RESULTAT_TESTS_LIST_2: "Le taux moyen de conformité du service en ligne s'élève à 82.94%.",
    RETOUR_CONTACT_TITLE: "Retour d'information et contact",
    RETOUR_CONTACT_BODY_1: "Si vous n'arrivez pas à accéder à un contenu ou à un service, vous pouvez contacter le responsable du site internet pour être orienté vers une alternative accessible ou obtenir le contenu sous une autre forme.",
    RETOUR_CONTACT_BODY_2: "Contacter le bureau national : ",
    RETOUR_CONTACT_LINK: "Page de contact",
    VOIES_RECOURS_TITLE: "Voies de recours",
    VOIES_RECOURS_BODY_1: "Cette procédure est à utiliser dans le cas suivant.",
    VOIES_RECOURS_BODY_2: "Vous avez signalé au responsable du site internet un défaut d'accessibilité qui vous empêche d'accéder à un contenu ou à un des services du portail et vous n'avez pas obtenu de réponse satisfaisante.",
    VOIES_RECOURS_BODY_3: "Écrire un message au Défenseur des droits",
    VOIES_RECOURS_BODY_4: "Contacter le délégué du Défenseur des droits près de chez vous",
    VOIES_RECOURS_BODY_5: "Envoyer un courrier par la poste (gratuit, ne pas mettre de timbre) : Défenseur des droits Libre réponse 71120 75342 Paris CEDEX 07",
    VOIES_RECOURS_BODY_6: "Contacter le Défenseur des droits par téléphone : 09 69 39 00 00",
    DATE_DECLARATION_TITLE: "Établissement de cette déclaration d'accessibilité",
    DATE_DECLARATION_BODY: "Cette déclaration a été établie le 30/05/2024.",
    TECHNO_TITLE: "Technologies utilisées pour la réalisation du site web",
    HTML: "HTML",
    CSS: "CSS",
    JAVACRIPT: "Javascript",
    REACT: "React 18",
    GROMMET: "Grommet v2",
    KEYCLOAK: "Keycloak",
    LEAFLET: "Leaflet",
    ENV_TEST: "Environnement de test",
    OUTILS_TITLE: "Agents utilisateurs, technologies d'assistance et outils utilisés pour vérifier l'accessibilité",
    OUTILS_BODY_1: "Les vérifications de restitution de contenus ont été réalisées avec les configurations suivantes :",
    OUTILS_BODY_2: "Les outils de vérification du code suivants ont été utilisés afin de vérifier la conformité aux tests du RGAA :",
    OUTILS_LIST_1_1: "Firefox et NVDA",
    OUTILS_LIST_1_2: "Safari et VoiceOver",
    OUTILS_LIST_1_3: "Firefox et Jaws",
    OUTILS_LIST_2_1: "Colour Contrast Analyser",
    OUTILS_LIST_2_2: "Extension \"Web Developer\"",
    OUTILS_LIST_2_3: "Extension \"Assistant RGAA\"",
    OUTILS_LIST_2_4: "Extension \"WCAG Contrast checker\"",
    OUTILS_LIST_2_5: "Extension \"ARC Toolkit\"",
    OUTILS_LIST_2_6: "Extension \"HeadingsMap\"",
    OUTILS_LIST_2_7: "Outils pour développeurs intégrés au navigateur Firefox",
    OUTILS_LIST_2_8: "Validateur HTML du W3C",
    PAGES_VERIFIEES_TITLE: "Pages du site ayant fait l'objet de la vérification de conformité",
    PAGE_ACCUEIL: "Page d'accueil",
    PAGE_RECHERCHE: "Lancer une recherche",
    PAGE_RESULTAT_RECHERCHE: "Résultat d'une recherche",
    PAGE_CREER_COMPTE: "Créer mon espace candidat",
    PAGE_INSCRIPTION_CONCOURS: "Inscription concours (Formulaire à onglet)",
    PAGE_ESPACE_CANDIDAT: "Espace candidat",
    PAGE_PLAN_SITE: "Plan du site",
    PAGE_CONTACT: "Contact",
    PAGE_RECUP_MDP: "Récupération du mot de passe",
    TEMPLATE_EMAIL: "Template email",
    CONTENUS_KO_TITLE: "Contenus non accessibles",
    CONTENUS_KO_BODY: "Les contenus listés ci-dessous ne sont pas accessibles pour les raisons suivantes.",
    NON_CONFORME_LIST_TITLE: "Non conformité",
    NON_CONFORME_LIST_BODY: "Ne sont listées ici que les non-conformités jugées les plus impactantes et présentes en quantités importantes dans les pages de l'échantillon. Sur demande, le résultat complet de l'audit peut être mis à disposition.",
    NON_CONFORME_LIST_1: "Certaines images porteuses d'information n'ont pas d'alternative ou celle-ci n'est pas pertinente",
    NON_CONFORME_LIST_2: "Certaines images de décoration ne sont pas correctement masquées aux technologies d'assistance",
    NON_CONFORME_LIST_3: "Certains contrastes ne sont pas suffisants",
    NON_CONFORME_LIST_4: "Certains composants Javascript ne sont pas compatibles avec les technologies d'assistance",
    NON_CONFORME_LIST_5: "Certains contenus sont dans des balises <div> ou <span> au lieu de <p>",
    NON_CONFORME_LIST_6: "Certains titres ne sont pas correctement balisés",
    NON_CONFORME_LIST_7: "Certains tableaux ne sont pas correctement structurés",
    NON_CONFORME_LIST_8: "Certaines listes ne sont pas correctement balisées",
    NON_CONFORME_LIST_9: "Certains champs de formulaire n'ont pas d'étiquette",
    NON_CONFORME_LIST_10: "Absence d'indication de format et d'exemple sur certains de champs de formulaire",
    NON_CONFORME_LIST_11: "Messages d'erreur non reliés aux champs de formulaire",
    NON_CONFORME_LIST_12: "Fichiers PDF non accessibles et sans alternative",
};