import { Box, Button, Collapsible, Text } from "grommet";

import { useEffect, useState } from "react";
import "leaflet/dist/leaflet.css";
import { FormDown, FormNext } from "grommet-icons";

import { messagesEspaceCandidat } from "../messages/messagesEspaceCandidat";
import { Inscription } from "./inscription/Inscription";
import { Etape } from "./etapes/Etape";
import { BlocPiecesAdministratives } from "./piecesadmin/BlocPiecesAdministratives";
import { useResponsive } from "../responsive";
import { LocaleDate } from "./components/LocaleDate";

const CandidatureTitle = ({ label, open, date, ...rest }) => {
    const Icon = open ? FormDown : FormNext;
    return (
        <Button hoverIndicator="background" {...rest}>
            <Box
                margin={undefined}
                direction="row"
                align="center"
                justify="between"
                pad="xsmall"
            >
                <Box direction="row" align="center">
                    <Icon role="img" color="brand" />
                    <Text size="xlarge" weight="bold">
                        {label}
                    </Text>
                </Box>
                <LocaleDate
                    date={date}
                    color="sicmicontrastygrey"
                    size="xlarge"
                    locale="fr-FR"
                />
            </Box>
        </Button>
    );
};

export const Candidature = (props) => {
    const [title, setTitle] = useState(undefined);

    useEffect(() => {
        if (props.candidature) {
            setTitle(
                messagesEspaceCandidat.CANDIDATURE +
                    " " +
                    props.candidature.categRecrutementSession +
                    " " +
                    messagesEspaceCandidat.AU_CONCOURS +
                    " " +
                    props.candidature.typeRecrutSession +
                    " : " +
                    props.candidature.gradeRecrutSession +
                    ((props.candidature.specialiteRecrutSession) ? messagesEspaceCandidat.CANDIDATURE_SPECIALITE + props.candidature.specialiteRecrutSession : "")
            );
        }
    }, [props.candidature]);

    const smallTheme = {
        gap: undefined,
    };
    const regularTheme = {
        gap: "medium",
    };

    const responsiveTheme = useResponsive(smallTheme, regularTheme);

    return (
        <Box gap="small">
            <CandidatureTitle
                open={props.open}
                label={title}
                date={props.candidature.datePremiereEpreuve}
                aria-expanded={props.open}
                aria-controls={"candidatureContent" + props.mapIndex}
                onClick={() => props.toggleCandidature(props.mapIndex)}
            />
            <Box id={"candidatureContent" + props.mapIndex}>
                <Collapsible open={props.open}>
                    {!props.candidature.migration && (
                        <Box gap={responsiveTheme.gap}>
                            <BlocPiecesAdministratives
                                idSession={props.candidature.idSession}
                                piecesAdmin={props.candidature.piecesAdmin}
                            />
                            <Inscription candidature={props.candidature} />
                            {props.candidature.etapes &&
                                props.candidature.etapes.map(function (
                                    etape,
                                    i
                                ) {
                                    return (
                                        <Etape
                                            key={i}
                                            etape={etape}
                                            mapIndex={props.mapIndex}
                                            idSession={
                                                props.candidature.idSession
                                            }
                                            idDossier={
                                                props.candidature.idDossier
                                            }
                                        />
                                    );
                                })}
                        </Box>
                    )}
                    {props.candidature.migration && (
                        <Box gap="small">
                            <Box
                                pad="small"
                                border={{ size: "small" }}
                                round="medium"
                                direction="row"
                            >
                                <Box
                                    direction="row"
                                    fill="horizontal"
                                    justify="center"
                                >
                                    <Text textAlign="center" size="xlarge">
                                        {
                                            messagesEspaceCandidat.SESSION_LEGACY_MIGRATION
                                        }
                                    </Text>
                                </Box>
                            </Box>
                        </Box>
                    )}
                </Collapsible>
            </Box>
        </Box>
    );
};
