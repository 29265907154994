import {
    Box,
    Button,
    Form,
    FormField,
    Heading,
    Layer,
    Text,
    TextInput,
} from "grommet";
import { StatusGood } from "grommet-icons";
import { useState } from "react";
import { agentLogin } from "../auth/agentLogin";
import { store } from "../auth/store";
import { EmailFormField } from "../inscription/inputs/EmailFormField";
import { FormFieldLabel } from "../inscription/inputs/FormFieldLabel";

import { messagesCommon } from "../messages/messagesCommon";
import { ModalSuccess } from "./ModalSuccess";
import { SpinnerWithText } from "./SpinnerWithText";

export const AccountUpdate = (props) => {
    const [oldPwd, setOldPwd] = useState("");
    const [email, setEmail] = useState(store.email);
    const [lastname, setLastName] = useState(store.nom);
    const [firstname, setFirstName] = useState(store.prenom);
    const [newPwd1, setNewPwd1] = useState("");
    const [newPwd2, setNewPwd2] = useState("");
    const [success, setSuccess] = useState(false);
    const [errorMsg, setErrorMsg] = useState(undefined);
    const [loading, setLoading] = useState(false);

    const onSubmit = () => {
        if (store.email === email.trim() && store.nom === lastname.trim()  && store.prenom === firstname.trim() && !newPwd2) {
            setErrorMsg(messagesCommon.ACCOUNT_NOTHING_CHANGED);
            return;
        }

        setLoading(true);
        if (store.fc_token_id) {
            agentLogin.updateEmail(email, (result: any) => {
                setLoading(false);
                if (result === true) {
                    setSuccess(true);
                } else if (result === "401") {
                    setErrorMsg(messagesCommon.ERROR_401);
                } else {
                    setErrorMsg(messagesCommon.BACKEND_ERROR);
                }
            });
        } else {
            agentLogin.updateAccount(oldPwd, newPwd2, email, lastname, firstname, (result: any) => {
                setLoading(false);
                if (result === true) {
                    store.nom = lastname;
                    store.prenom = firstname;
                    setSuccess(true);
                } else if (result === "401") {
                    setErrorMsg(messagesCommon.CHANGE_PWD_INCORRECT);
                } else {
                    setErrorMsg(messagesCommon.BACKEND_ERROR);
                }
            });
        }
    };

    const closeModal = () => {
        props.onClose(true);
    };

    const passwordRegex = "[^\\`'\" ]";

    return (
        <Layer 
            position="center"
            role="dialog"
            aria-modal="true"
            tabIndex={-1}
            onEsc={closeModal}
            aria-label={messagesCommon.UPDATE_ACCOUNT_MODAL_HEADER}
        >
            <Box pad="medium" gap="small" width="large">
                {!success && (
                    <Form
                        onSubmit={() => onSubmit()}
                        messages={{ required: "Requis" }}
                        validate="change"
                        onChange={() => {
                            setErrorMsg(undefined);
                        }}
                    >
                        <Box alignSelf="center" margin={{ bottom: "small" }}>
                            <Heading alignSelf="center" level={3} margin="none">
                                {messagesCommon.UPDATE_ACCOUNT_MODAL_HEADER}
                            </Heading>
                        </Box>
                        <Box justify="center" align="center" gap="medium">
                            {!store.fc_token_id && (
                                <Text textAlign="center">
                                    {messagesCommon.UPDATE_ACCOUNT_MODAL_BODY}
                                </Text>
                            )}

                            <Box width="medium" gap="small">
                                <FormField
                                    label={
                                        <FormFieldLabel
                                            label={messagesCommon.CURRENT_MDP}
                                            required
                                        />
                                    }
                                    htmlFor="oldpwd"
                                >
                                    <TextInput
                                        plain
                                        name="oldpwd"
                                        type="password"
                                        value={oldPwd}
                                        required
                                        onChange={(event) =>
                                            setOldPwd(event.target.value)
                                        }
                                    />
                                </FormField>
                                <EmailFormField
                                    label="Email"
                                    required
                                    name="email"
                                    value={email}
                                    onChange={setEmail}
                                />
                                {!store.fc_token_id && (
                                    <FormField
                                        label={
                                            <FormFieldLabel
                                                label={messagesCommon.LASTNAME}
                                                required
                                            />
                                        }
                                        htmlFor="oldpwd"
                                    >
                                        <TextInput
                                            plain
                                            name="lastname"
                                            type="text"
                                            value={lastname}
                                            required
                                            onChange={(event) =>
                                                setLastName(event.target.value)
                                            }
                                        />
                                    </FormField>
                                )}
                                {!store.fc_token_id && (
                                    <FormField
                                        label={
                                            <FormFieldLabel
                                                label={messagesCommon.FIRSTNAME}
                                                required
                                            />
                                        }
                                        htmlFor="oldpwd"
                                    >
                                        <TextInput
                                            plain
                                            name="firstname"
                                            type="text"
                                            value={firstname}
                                            required
                                            onChange={(event) =>
                                                setFirstName(event.target.value)
                                            }
                                        />
                                    </FormField>
                                )}
                                {!store.fc_token_id && (
                                    <FormField
                                        label={
                                            <FormFieldLabel
                                                label={messagesCommon.NEW_MDP}
                                            />
                                        }
                                        htmlFor="newpwd"
                                        name="newpwd"
                                        validate={[
                                            (newpwd1) => {
                                                if (
                                                    newpwd1 &&
                                                    !new RegExp(
                                                        "^" +
                                                            passwordRegex +
                                                            "{6,}$"
                                                    ).test(newpwd1)
                                                )
                                                    return "6 caractères minimum (\\ ' \" ` et espace sont interdits)";
                                                return undefined;
                                            },
                                        ]}
                                    >
                                        <TextInput
                                            plain
                                            name="newpwd"
                                            type="password"
                                            value={newPwd1}
                                            onChange={(event) =>
                                                setNewPwd1(event.target.value)
                                            }
                                        />
                                    </FormField>
                                )}
                                {!store.fc_token_id && (
                                    <FormField
                                        label={
                                            <FormFieldLabel
                                                label={
                                                    messagesCommon.NEW_MDP_CONFIRM
                                                }
                                            />
                                        }
                                        htmlFor="newpwd2"
                                        name="newpwd2"
                                        validate={[
                                            (newpwd2) => {
                                                if (
                                                    newpwd2 &&
                                                    newpwd2 !== newPwd1
                                                )
                                                    return messagesCommon.MDP_NOT_IDENTICAL;
                                                return undefined;
                                            },
                                        ]}
                                    >
                                        <TextInput
                                            plain
                                            name="newpwd2"
                                            type="password"
                                            value={newPwd2}
                                            onChange={(event) =>
                                                setNewPwd2(event.target.value)
                                            }
                                        />
                                    </FormField>
                                )}
                                {errorMsg && (
                                    <Text weight="bold" color="status-critical">
                                        {errorMsg}
                                    </Text>
                                )}
                            </Box>
                        </Box>
                        <Box
                            as="footer"
                            gap="small"
                            direction="row"
                            pad={{ top: "medium", bottom: "small" }}
                            alignSelf="center"
                            justify="center"
                        >
                            {loading && <SpinnerWithText texte="Chargement" />}
                            {!loading && (
                                <Button
                                    label={
                                        <Text color="brand">
                                            {messagesCommon.ANNULER}
                                        </Text>
                                    }
                                    secondary
                                    onClick={() => props.onClose(false)}
                                />
                            )}
                            {!loading && (
                                <Button
                                    type="submit"
                                    label={messagesCommon.VALIDER}
                                    primary
                                />
                            )}
                        </Box>
                    </Form>
                )}
                {success && (
                    <ModalSuccess message={!store.fc_token_id
                        ? messagesCommon.ACCOUNT_UPDATED
                        : messagesCommon.EMAIL_UPDATED} onClose={closeModal} />
                )}
            </Box>
        </Layer>
    );
};
