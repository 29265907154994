import { Grommet, Main } from "grommet";

import { messagesCommon } from "../messages/messagesCommon";
import { HeaderSicmi } from "../common/HeaderSicmi";
import { FooterSicmi } from "../common/FooterSicmi";
import { theme } from "../theme";
import { paths } from "../paths";
import { useEffect } from "react";
import { DeclarationAccessibiliteContent } from "./DeclarationAccessibiliteContent";

export const DeclarationAccessibilite = () => {
    const breadcrumbs = [
        { label: "Accueil", href: paths.HOME },
        { label: messagesCommon.ACCESSIBILITE },
    ];

    useEffect(() => {
        document.title = messagesCommon.ACCESSIBILITE + " – " + messagesCommon.MAIN_PAGE_TITLE;
    }, []);

    return (
        <Grommet full theme={theme}>
                <HeaderSicmi
                    title={messagesCommon.ACCESSIBILITE}
                    breadcrumbs={breadcrumbs}
                />
                <Main 
                    role="main"
                    align="center"
                    direction="column"
                    fill="horizontal"
                    pad={{ top: "large" }}
                    flex={false}
                    margin={{ bottom: "xlarge" }}
                >
                    <DeclarationAccessibiliteContent />
                    
                </Main>
                <FooterSicmi />
        </Grommet>
    );
};