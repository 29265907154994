export const messagesEtatCivil = {
    PRENOM: "Prénom usuel",
    NOM: "Nom de naissance",
    CIVILITE: "Civilité",
    CIVILITE_ARIA: "Sélection de la civilité : M = Monsieur, Mme = Madame",
    NOM_MARITAL: "Nom marital",
    AUTRE_PRENOMS: "Autres prénoms",
    ADRESSE: "Adresse",
    COMP_ADRESSE: "Complément d'adresse",
    COMMUNE: "Commune",
    CP: "Code postal",
    PAYS: "Pays",
    DATE_NAISS: "Date de naissance",
    LIEU_NAISS: "Lieu de naissance",
    NATIONALITE: "Nationalité (pays)",
    NATIONALITE_EN_COURS_ACQUI: "Nationalité en cours d'acquisition",
    PAYS_NAISS: "Pays de naissance",
    DEP_NAISS: "Département de naissance",
    TEL: "Téléphone",
    HOMME: "M",
    FEMME: "Mme"
    
};