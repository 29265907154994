import { Box, Text } from "grommet";
import { Link } from "react-router-dom";

import { messagesEspaceCandidat } from "../../messages/messagesEspaceCandidat";
import { VerticalDivider } from "../components/VerticalDivider";
import { Logo } from "../Logo";
import { LocaleDate } from "../components/LocaleDate";
import { getTheme } from "../etapeThemes";

export const DemandeEnvoyee = (props) => {
    const typeCommun = {
        type: "ok",
        typeDone: "doneOK",
        header: messagesEspaceCandidat.DEMANDE_ENVOYEE,
        body: messagesEspaceCandidat.DEMANDE_ENVOYEE_BODY,
        bodyDone: messagesEspaceCandidat.DEMANDE_ENVOYEE_BODY,
    };

    const themeTypeForStatutDossier = {
        V: typeCommun,
        R: typeCommun,
        T: typeCommun,
        E: {
            type: "current",
            typeDone: "doneKO",
            header: messagesEspaceCandidat.DEMANDE_ENREGISTREE,
            body: messagesEspaceCandidat.POURSUIVRE_INSCRIPTION,
            bodyDone: messagesEspaceCandidat.CLOTURE_INSCRIPTION,
        },
    };

    const inscription = (() => {
        let type = themeTypeForStatutDossier[props.statutDossier];
        return props.cloture
            ? getTheme(type.typeDone, type.header, type.bodyDone)
            : getTheme(type.type, type.header, type.body);
    })();

    return (
        <Box direction="row" gap="small" fill="horizontal">
            <Box direction="column" width={{ min: "xxsmall", max: "xxsmall" }}>
                <Box direction="row">
                    <Logo background={inscription.theme.backgroundColor}>
                        {inscription.theme.logo}
                    </Logo>
                </Box>

                <VerticalDivider
                    background={{
                        color: inscription.theme.dividerColor,
                        doted: props.statutDossier === "E" && !props.cloture,
                    }}
                />
            </Box>
            <Box direction="column" gap="small" fill="horizontal">
                <Box>
                    <LocaleDate
                        date={props.dateCreation}
                        color="sicmicontrastygrey"
                        locale="fr-FR"
                    />
                    <Text weight="bold" size="large" color="sicmiblack">
                        {inscription.header}
                    </Text>
                </Box>

                <Box
                    border={{ size: "medium" }}
                    round="small"
                    margin={{ bottom: "small" }}
                >
                    <Box pad="xsmall" round="small" background="sicmiwhite">
                        {props.statutDossier === "E" && !props.cloture && (
                            <Link to={`/inscription/${props.idSession}`}>
                                <Text margin="xsmall">{inscription.body}</Text>
                            </Link>
                        )}
                        {(props.statutDossier !== "E" ||
                            props.cloture) && (
                                <Text margin="xsmall">{inscription.body}</Text>
                            )}
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};
