import { Box, Button, Heading, Layer, Text } from "grommet";
import { useState } from "react";

import { messagesEspaceCandidat } from "../messages/messagesEspaceCandidat";
import { RattachementLogin } from "./RattachementLogin";
import { RattachementEmail } from "./RattachementEmail";
import { useResponsive } from "../responsive";

export const RattachementModal = (props) => {
    const [rattachementLogin, setRattachementLogin] = useState(false);
    const [rattachementEmail, setRattachementEmail] = useState(false);

    const fermerModal = () => {
        setRattachementEmail(false);
        setRattachementLogin(false);
        props.setAfficherRattachement(false);
    };

    const smallTheme = {
        modalWidth: "950%",
    };
    const regularTheme = {
        modalWidth: "70%",
    };

    const responsiveTheme = useResponsive(smallTheme, regularTheme);

    return (
        props.afficher && (
            <Layer
                onEsc={fermerModal}
                onClickOutside={fermerModal}
                position="center"
                role="dialog"
                aria-modal="true"
                tabIndex={-1}
                aria-labelledby="rattachementTitle"
            >
                <Box fill justify="center">
                    <Box
                        background="white"
                        pad="medium"
                        gap="small"
                        width="large"
                    >
                        <Box alignSelf="center" justify="center" width="70%">
                            <Heading alignSelf="center" level={3} margin="none" id="rattachementTitle">
                                {messagesEspaceCandidat.RATTACHEMENT}
                            </Heading>
                        </Box>
                        {rattachementLogin === false &&
                            rattachementEmail === false && (
                                <Box
                                    alignSelf="center"
                                    margin={{ top: "small" }}
                                    gap="medium"
                                    width={responsiveTheme.modalWidth}
                                >
                                    <Button
                                        label={
                                            <Text color="white">
                                                <strong>
                                                    {
                                                        messagesEspaceCandidat.RATTACHEMENT_LOGIN
                                                    }
                                                </strong>
                                            </Text>
                                        }
                                        onClick={() =>
                                            setRattachementLogin(true)
                                        }
                                        primary
                                    />
                                    <Button
                                        label={
                                            <Text color="white">
                                                <strong>
                                                    {
                                                        messagesEspaceCandidat.RATTACHEMENT_EMAIL
                                                    }
                                                </strong>
                                            </Text>
                                        }
                                        onClick={() =>
                                            setRattachementEmail(true)
                                        }
                                        primary
                                    />
                                </Box>
                            )}
                        {rattachementLogin === true && <RattachementLogin />}
                        {rattachementEmail === true && <RattachementEmail />}

                        <Box
                            as="footer"
                            gap="small"
                            direction="row"
                            pad={{ top: "medium", bottom: "small" }}
                            alignSelf="center"
                            justify="center"
                            width="70%"
                        >
                            <Button
                                label={
                                    <Text color="brand">
                                        <strong>
                                            {messagesEspaceCandidat.FERMER}
                                        </strong>
                                    </Text>
                                }
                                onClick={fermerModal}
                                secondary
                            />
                        </Box>
                    </Box>
                </Box>
            </Layer>
        )
    );
};
