import { Box, Text } from "grommet";

import { messagesCommon } from "../messages/messagesCommon";
import { RoutedAnchor } from "../anchor/RoutedAnchor";
import { paths } from "../paths";
import { store } from "../auth/store";
import { messagesPlanSite } from "../messages/messagesPlanSite";
import { useResponsive } from "../responsive";

export const PlanSiteContent = () => {
    const smallTheme = {
        width: "100%",
        fill: false,
    };

    const regularTheme = {
        width: "xlarge",
        fill: "vertical",
    };

    const responsiveTheme = useResponsive(smallTheme, regularTheme);

    return (
        <Box
            direction="column"
            fill="horizontal"
            pad={{ top: "large" }}
            width={responsiveTheme.width}
            flex={false}
            align="center"
            margin={{ bottom: "xlarge" }}
        >
            <Box
                justify="start"
                as="nav"
                role="navigation"
                a11yTitle={messagesPlanSite.PAGE_TITLE}
                title={messagesPlanSite.PAGE_TITLE}
            >
                <Box direction="row">
                    <Text
                        margin={{ right: "medium" }}
                        color="brand"
                        size="xxlarge"
                    >
                        •
                    </Text>
                    <RoutedAnchor
                        to={paths.HOME}
                        label={messagesCommon.ACCUEIL}
                        size="xxlarge"
                    />
                </Box>
                <Box direction="row">
                    <Text
                        margin={{ right: "medium" }}
                        color="brand"
                        size="xxlarge"
                    >
                        •
                    </Text>
                    <RoutedAnchor
                        to={paths.LISTE_SESSIONS}
                        label={messagesCommon.LISTE_SESSIONS}
                        size="xxlarge"
                    />
                </Box>
                <Box direction="row">
                    <Text
                        margin={{ right: "medium" }}
                        color="brand"
                        size="xxlarge"
                    >
                        •
                    </Text>
                    {store.connected ? (
                        <>
                            <RoutedAnchor
                                to={paths.ESPACE_CANDIDAT}
                                label={messagesCommon.ESPACE_CANDIDAT}
                                size="xxlarge"
                            />
                            {/* On colore en blanc pour gruger et cacher le texte sans péter le style */}
                            <Text color="white" size="medium">
                                {messagesPlanSite.NEED_LOGIN}
                            </Text>
                        </>
                    ) : (
                        <>
                            <Text
                                color="brand"
                                margin={{ right: "xsmall" }}
                                weight="bold"
                                size="xxlarge"
                            >
                                {messagesCommon.ESPACE_CANDIDAT}
                            </Text>
                            <Text color="sicmired" size="medium">
                                {messagesPlanSite.NEED_LOGIN}
                            </Text>
                        </>
                    )}
                </Box>
                <Box direction="row">
                    <Text
                        margin={{ right: "medium" }}
                        color="brand"
                        size="xxlarge"
                    >
                        •
                    </Text>
                    <RoutedAnchor
                        to={paths.ACCESSIBILITE}
                        label={messagesCommon.ACCESSIBILITE}
                        size="xxlarge"
                    />
                </Box>
                <Box direction="row">
                    <Text
                        margin={{ right: "medium" }}
                        color="brand"
                        size="xxlarge"
                    >
                        •
                    </Text>
                    <RoutedAnchor
                        to={paths.SITE_MAP}
                        label={messagesCommon.SITE_MAP}
                        size="xxlarge"
                    />
                </Box>
                <Box direction="row">
                    <Text
                        margin={{ right: "medium" }}
                        color="brand"
                        size="xxlarge"
                    >
                        •
                    </Text>
                    <RoutedAnchor
                        to={paths.CONTACT}
                        label={messagesCommon.CONTACT}
                        size="xxlarge"
                    />
                </Box>
            </Box>
        </Box>
    );
};
