export const messagesCommon = {
  MDP_OUBLIE: "Mot de passe oublié ?",
  BTN_CONNECT: "Se connecter à votre espace candidat",
  MAIN_PAGE_TITLE: "Inscription aux concours du ministère de l'Intérieur",
  HEADER_URL: "inscription-concours.interieur.gouv.fr",
  CREATE_ACCOUNT: "Créer mon espace candidat",
  DROITS_MODIF: "Droits de modification",
  CONFIRM_RATTACHEMENT: "Confirmation de rattachement de compte",
  OR_CAPS: "OU",
  PASSWORD: "Mot de passe",
  SITE_MAP: "Plan du site",
  ACCESSIBILITE: "Déclaration d'accessibilité",
  ACCESSIBILITE_FOOTER: "Accessibilité : non conforme",
  CONTACT: "Contact",
  SITE_MINISTERE: "Site du Ministère de l'Intérieur",
  ACCUEIL: "Accueil",
  LISTE_SESSIONS: "Liste des sessions",
  ESPACE_CANDIDAT: "Espace candidat",
  DECONNEXION: "Déconnexion",
  SELECT: "Sélectionner",
  VALIDER: "Valider",
  SUPPRIMER: "Je supprime mon inscription",
  SOUMETTRE: "Je valide mon inscription. Elle est envoyée à l'administration et je ne pourrai plus la modifier",
  ANNULER: "Annuler",
  CONFIRMER: "Confirmer",
  ENREGISTRER: "J'enregistre mon inscription pour la compléter plus tard",
  ERROR_LOGIN: "Email ou mot de passe incorrect.",
  ERROR_RATTACHEMENT_LOGIN: "Pseudo ou mot de passe incorrect.",
  BACKEND_ERROR: "Une erreur est survenue.",
  ERROR: "Erreur",
  BACK_HOME: "Revenir à l'accueil",
  ERROR_401: "Vous n'êtes pas autorisé à faire cette action ou accéder à cette page.",
  ERROR_404: "Cette page n'existe pas ou nécessite d'être authentifié.",
  ERROR_AUTHENTIFIED:
    "Cette page n'est pas accessible en étant connecté à un compte.",
  INVALID_FORM: "Formulaire invalide",
  PSEUDO: "Pseudo",
  MDP_OUBLIE_MODAL: "Mot de passe oublié",
  MDP_OUBLIE_MODAL_BODY:
    "Veuillez entrer votre adresse email. Si celle-ci est associée à un compte," +
    " vous recevrez un email avec un lien pour changer votre mot de passe",
  MDP_OUBLIE_MODAL_WARNING:
    "Attention, si votre première connexion à l'application fut à l'aide de FranceConnect," +
    " vous ne pouvez pas modifier votre mot de passe depuis notre site.",
  MDP_OUBLIE_SUCCESS: "Votre demande a été prise en compte. Vous recevrez un email si votre adresse correspond à un compte existant",
  FC_EMAIL_1:
    "Nous n'avons pas pu obtenir d'adresse email avec votre compte FranceConnect.",
  FC_EMAIL_2:
    "Veuillez entrer une adresse email valide pour pouvoir continuer avec le processus de connexion.",
  FC_WAIT: "Veuillez patienter pendant que nous vous authentifions",
  FC_ERROR: "Une erreur s'est produite.",
  FC_ERROR_BODY:
    "Nous ne sommes pas en mesure de vous authentifier pour l'instant",
  FILE_INPUT_MSG: {
    browse: "Parcourir",
    dropPrompt: "Déposer un fichier ici ou",
    dropPromptMultiple: "Déposer des fichiers ici ou",
    files: "fichiers",
    remove: "supprimer",
    removeAll: "tout supprimer",
    maxFile: "Déposer un maximum de {max} fichiers uniquement.",
  },
  UPDATE_ACCOUNT_MODAL_HEADER: "Mise à jour de votre compte",
  UPDATE_ACCOUNT_MODAL_BODY: "Vous devez renseigner votre mot de passe actuel afin de pouvoir changer de mot de passe ou d'adresse email",
  CURRENT_MDP: "Mot de passe actuel",
  NEW_MDP: "Nouveau mot de passe",
  NEW_MDP_CONFIRM: "Confirmer le nouveau mot de passe",
  WELCOME: "Bienvenue",
  UPDATE_ACCOUNT: "Modifier le compte",
  MDP_NOT_IDENTICAL: "Le mot de passe n'est pas identique",
  CHANGE_PWD_INCORRECT: "Connexion refusée : mot de passe incorrect ou connexion actuelle expirée",
  ACCOUNT_UPDATED: "Votre compte a été mis à jour.",
  EMAIL_UPDATED: "Votre email a été mis à jour",
  FERMER: "Fermer",
  EMAIL: "Email",
  ACCOUNT_NOTHING_CHANGED: "Vous n'avez pas modifié vos informations",
  LOADING: "Chargement",
  FATAL_ERROR: "Erreur",
  FATAL_401: "Vous n'êtes pas connecté ou votre connexion à l'application a expiré, vous allez être redirigé vers la page d'accueil.",
  FATAL_UNKNOW: "Une erreur s'est produite, vous allez être redirigé vers la page d'accueil.",
  OK: "OK",
  ARIA_FRANCECONNECT: "Connexion France Connect",
  MINISTERE_INTERIEUR_LOGO: "Logo du Ministère de l'Intérieur et devise \"Liberté, Egalité, Fraternité\"",
  LASTNAME: "Nom",
  FIRSTNAME: "Prénom",
  ALERT_LASTNAME_FIRSTNAME: "Le nom et le prénom renseignés dans le formulaire sont identiques. Êtes-vous sûr de vouloir valider celui-ci ? ",
  BREADCRUMBS: "Fil d'ariane",

};
