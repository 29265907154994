import { Box, Button, Form, Heading, Layer, MaskedInput, Text } from "grommet";
import { MailOption } from "grommet-icons";
import { useState } from "react";
import { agentLogin } from "../auth/agentLogin";

import { messagesCommon } from "../messages/messagesCommon";
import { ModalSuccess } from "./ModalSuccess";

export const PasswordReset = (props) => {

    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);

    const resetPassword = (event) => {
        setLoading(true);
        agentLogin.resetPassword(
            event.value.emailLogin,
            (result) => {
                setLoading(false);
                if (result === true) {
                    setSuccess(true);
                }
            }
        );
    };

    const closeModal = () => {
        props.onClose(false);
    };


    return (
        <Layer
            onEsc={() => closeModal()}
            onClickOutside={() => closeModal()}
            position="center"
            role="dialog"
            aria-modal="true"
            tabIndex={-1}
            aria-label={messagesCommon.MDP_OUBLIE_MODAL}
        >
            <Box pad="medium" gap="small" width="large">
                {!success && (
                <Form onSubmit={resetPassword}>
                    <Box alignSelf="center" margin={{bottom: "small"}}>
                        <Heading alignSelf="center" level={3} margin="none">
                            {messagesCommon.MDP_OUBLIE_MODAL}
                        </Heading>
                    </Box>
                    <Box justify="center" align="center" gap="medium">
                        <Text textAlign="center">
                            {messagesCommon.MDP_OUBLIE_MODAL_BODY}
                        </Text>
                        {/*<Text
                            size="small"
                            textAlign="center"
                            color="status-critical"
                        >
                            {messagesCommon.MDP_OUBLIE_MODAL_WARNING}
                </Text>*/}
                        <Box width="medium" gap="small">
                            <MaskedInput
                                reverse
                                icon={<MailOption />}
                                name="emailLogin"
                                required
                                a11yTitle={messagesCommon.EMAIL}
                                title={messagesCommon.EMAIL}
                                mask={[
                                    {
                                        regexp: /^[\w\-_.]+$/,
                                        placeholder: "exemple",
                                    },
                                    { fixed: "@" },
                                    {
                                        regexp: /^[\w-]+$/,
                                        placeholder: "hote",
                                    },
                                    { fixed: "." },
                                    {
                                        regexp: /^[\w.]+$/,
                                        placeholder: "com",
                                    },
                                ]}
                            />
                        </Box>
                    </Box>
                    <Box
                        as="footer"
                        gap="small"
                        direction="row"
                        pad={{ top: "medium", bottom: "small" }}
                        alignSelf="center"
                        justify="center"
                    >
                        <Button
                            label={
                                <Text color="brand">
                                    {messagesCommon.ANNULER}
                                </Text>
                            }
                            secondary
                            disabled={loading}
                            onClick={() => props.onClose(false)}
                        />
                        <Button
                            type="submit"
                            disabled={loading}
                            label={messagesCommon.VALIDER}
                            primary
                        />
                    </Box>
                </Form>)}
                {success && (
                    <ModalSuccess message={messagesCommon.MDP_OUBLIE_SUCCESS} onClose={closeModal} />
                )}
            </Box>
        </Layer>
    );
};
