import { Box, Button, CheckBox, Heading, Layer, Text } from "grommet";
import { useEffect, useState } from "react";

import { messagesAccueil } from "../messages/messagesAccueil";

export const AccueilModal = () => {
    const localStorageKey = "sicmi_neplusafficheralerte";

    const [noMoreDisplay, setNoMoreDisplay] = useState(false);
    const [displayModal, setDisplayModal] = useState(true);

    useEffect(() => {
        if (localStorage.getItem(localStorageKey) !== null) {
            setDisplayModal(false);
        }
    });

    const onDismiss = () => {
        if (noMoreDisplay) {
            localStorage.setItem(localStorageKey, "true");
        }

        setDisplayModal(false);
    };

    return (
        displayModal && (
            <Layer 
                position="center"
                role="dialog"
                aria-modal="true"
                tabIndex={-1}
                onEsc={onDismiss}
                aria-labelledby="legacyDossierTitle"
            >
                <Box pad="medium" gap="small" width="medium">
                    <Heading level={3} margin="none" id="legacyDossierTitle">
                        {messagesAccueil.LEGACY_DOSSIER_MODAL}
                    </Heading>
                    <Text>{messagesAccueil.LEGACY_DOSSIER_MODAL_BODY}</Text>

                    <Box
                        as="footer"
                        gap="small"
                        direction="row"
                        align="center"
                        justify="between"
                        pad={{ top: "medium", bottom: "small" }}
                    >
                        <CheckBox
                            label="Ne plus afficher"
                            checked={noMoreDisplay}
                            onChange={(event) =>
                                setNoMoreDisplay(event.target.checked)
                            }
                        />
                        <Button
                            label={messagesAccueil.LEGACY_DOSSIER_MODAL_OK}
                            onClick={onDismiss}
                            primary
                        />
                    </Box>
                </Box>
            </Layer>
        )
    );
};
