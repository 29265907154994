import { Anchor, Box, Heading, Paragraph } from "grommet";

import { RoutedAnchor } from "../anchor/RoutedAnchor";
import { paths } from "../paths";
import { useResponsive } from "../responsive";
import { messagesAccessibilite } from "../messages/messagesAccessibilite";

export const DeclarationAccessibiliteContent = () => {
    const smallTheme = {
        width: "100%",
    };

    const regularTheme = {
        width: "50%",
    };

    const responsiveTheme = useResponsive(smallTheme, regularTheme);

    return (
        <Box style={{ maxWidth: responsiveTheme.width }}>
            <Heading>{messagesAccessibilite.ACCESSIBILITY_TITLE}</Heading>
            <Paragraph fill>
                {messagesAccessibilite.ACCESSIBILITY_BODY_1}
            </Paragraph>
            <Paragraph fill>
                {messagesAccessibilite.ACCESSIBILITY_BODY_2}
            </Paragraph>
            <ul>
                <li>
                    <Anchor
                        target="_blank"
                        href="https://www.interieur.gouv.fr/sites/minint/files/medias/documents/2023-10/Schema%20pluriannuel%20Accessibilit%C3%A9-ministere-interieur-et-outre-mer-2022%202024-accessible.pdf"
                        label={messagesAccessibilite.ACCESSIBILITY_LINK_1}
                    />
                </li>
                <li>
                    <Anchor
                        target="_blank"
                        href="https://www.interieur.gouv.fr/sites/minint/files/medias/documents/2023-10/Plan%20annuel-accessibilite-2023-%20Accessible.pdf"
                        label={messagesAccessibilite.ACCESSIBILITY_LINK_2}
                    />
                </li>
                <li>
                    <Anchor
                        target="_blank"
                        href="https://www.interieur.gouv.fr/sites/minint/files/medias/documents/2022-06/Plan%20annuel%20d%27accessibilite%202022.pdf"
                        label={messagesAccessibilite.ACCESSIBILITY_LINK_3}
                    />
                </li>
            </ul>
            <Paragraph fill>
                {messagesAccessibilite.ACCESSIBILITY_BODY_3}
            </Paragraph>

            <Heading level={2}>
                {messagesAccessibilite.ETAT_CONFORMITE_TITLE}
            </Heading>
            <Paragraph fill>
                <b>{messagesAccessibilite.ETAT_CONFORMITE_BODY_1}</b>
                {messagesAccessibilite.ETAT_CONFORMITE_BODY_2}
                <Anchor
                    target="_blank"
                    href="https://www.numerique.gouv.fr/publications/rgaa-accessibilite/methode-rgaa/criteres/#contenu"
                    label={messagesAccessibilite.ETAT_CONFORMITE_BODY_3}
                />
                {messagesAccessibilite.ETAT_CONFORMITE_BODY_4}
            </Paragraph>

            <Heading level={3}>
                {messagesAccessibilite.RESULTAT_TESTS_TITLE}
            </Heading>
            <Paragraph fill margin={"none"}>
                {messagesAccessibilite.RESULTAT_TESTS_BODY_1}
                <Anchor
                    target="_blank"
                    href="https://accessibilitenumerique.empreintedigitale.fr/"
                    label={messagesAccessibilite.RESULTAT_TESTS_BODY_SOCIETE}
                />
                {messagesAccessibilite.RESULTAT_TESTS_BODY_2}
            </Paragraph>
            <ul>
                <li>
                    <b>
                        {messagesAccessibilite.RESULTAT_TESTS_LIST_1_POURCENT}
                    </b>
                    {messagesAccessibilite.RESULTAT_TESTS_LIST_1}
                </li>
                <li>{messagesAccessibilite.RESULTAT_TESTS_LIST_2}</li>
            </ul>

            <Heading level={2}>
                {messagesAccessibilite.CONTENUS_KO_TITLE}
            </Heading>
            <Paragraph fill margin={"none"}>
                {messagesAccessibilite.CONTENUS_KO_BODY}
            </Paragraph>

            <Heading level={3}>
                {messagesAccessibilite.NON_CONFORME_LIST_TITLE}
            </Heading>
            <Paragraph fill margin={"none"}>
                {messagesAccessibilite.NON_CONFORME_LIST_BODY}
            </Paragraph>
            <ul>
                <li>{messagesAccessibilite.NON_CONFORME_LIST_1}</li>
                <li>{messagesAccessibilite.NON_CONFORME_LIST_2}</li>
                <li>{messagesAccessibilite.NON_CONFORME_LIST_3}</li>
                <li>{messagesAccessibilite.NON_CONFORME_LIST_4}</li>
                <li>{messagesAccessibilite.NON_CONFORME_LIST_5}</li>
                <li>{messagesAccessibilite.NON_CONFORME_LIST_6}</li>
                <li>{messagesAccessibilite.NON_CONFORME_LIST_7}</li>
                <li>{messagesAccessibilite.NON_CONFORME_LIST_8}</li>
                <li>{messagesAccessibilite.NON_CONFORME_LIST_9}</li>
                <li>{messagesAccessibilite.NON_CONFORME_LIST_10}</li>
                <li>{messagesAccessibilite.NON_CONFORME_LIST_11}</li>
                <li>{messagesAccessibilite.NON_CONFORME_LIST_12}</li>
            </ul>

            {/* INFO SUR LA DECLARATION */}

            <Heading level={2}>
                {messagesAccessibilite.DATE_DECLARATION_TITLE}
            </Heading>
            <Paragraph fill>
                {messagesAccessibilite.DATE_DECLARATION_BODY}
            </Paragraph>

            <Heading level={3}>{messagesAccessibilite.TECHNO_TITLE}</Heading>
            <ul>
                <li>{messagesAccessibilite.HTML}</li>
                <li>{messagesAccessibilite.CSS}</li>
                <li>{messagesAccessibilite.JAVACRIPT}</li>
                <li>{messagesAccessibilite.REACT}</li>
                <li>{messagesAccessibilite.GROMMET}</li>
                <li>{messagesAccessibilite.LEAFLET}</li>
            </ul>

            <Heading fill level={4}>
                {messagesAccessibilite.OUTILS_TITLE}
            </Heading>
            <Paragraph margin={"none"} fill>
                {messagesAccessibilite.OUTILS_BODY_1}
            </Paragraph>
            <ul>
                <li>{messagesAccessibilite.OUTILS_LIST_1_1}</li>
                <li>{messagesAccessibilite.OUTILS_LIST_1_2}</li>
                <li>{messagesAccessibilite.OUTILS_LIST_1_3}</li>
            </ul>

            <Paragraph margin={"none"} fill>
                {messagesAccessibilite.OUTILS_BODY_2}
            </Paragraph>
            <ul>
                <li>{messagesAccessibilite.OUTILS_LIST_2_1}</li>
                <li>{messagesAccessibilite.OUTILS_LIST_2_2}</li>
                <li>{messagesAccessibilite.OUTILS_LIST_2_3}</li>
                <li>{messagesAccessibilite.OUTILS_LIST_2_4}</li>
                <li>{messagesAccessibilite.OUTILS_LIST_2_5}</li>
                <li>{messagesAccessibilite.OUTILS_LIST_2_6}</li>
                <li>{messagesAccessibilite.OUTILS_LIST_2_7}</li>
                <li>{messagesAccessibilite.OUTILS_LIST_2_8}</li>
            </ul>

            <Heading fill level={4}>
                {messagesAccessibilite.PAGES_VERIFIEES_TITLE}
            </Heading>
            <ul>
                <li>
                    <Anchor
                        target="_blank"
                        href="https://www.inscription-concours.interieur.gouv.fr/"
                        label={messagesAccessibilite.PAGE_ACCUEIL}
                    />
                </li>
                <li>
                    <Anchor
                        target="_blank"
                        href="https://www.inscription-concours.interieur.gouv.fr/recherche/sessions"
                        label={messagesAccessibilite.PAGE_RECHERCHE}
                    />
                </li>
                <li>
                    <Anchor
                        target="_blank"
                        href="https://www.inscription-concours.interieur.gouv.fr/recherche/sessions?recherche=adjoint"
                        label={messagesAccessibilite.PAGE_RESULTAT_RECHERCHE}
                    />
                </li>
                <li>
                    <Anchor
                        target="_blank"
                        href="https://www.inscription-concours.interieur.gouv.fr/creationcandidat"
                        label={messagesAccessibilite.PAGE_CREER_COMPTE}
                    />
                </li>
                <li>
                    <Anchor
                        target="_blank"
                        href="https://www.inscription-concours.interieur.gouv.fr/inscription/7666"
                        label={messagesAccessibilite.PAGE_INSCRIPTION_CONCOURS}
                    />
                </li>
                <li>
                    <Anchor
                        target="_blank"
                        href="https://www.inscription-concours.interieur.gouv.fr/espacecandidat"
                        label={messagesAccessibilite.PAGE_ESPACE_CANDIDAT}
                    />
                </li>
                <li>
                    <Anchor
                        target="_blank"
                        href="https://www.inscription-concours.interieur.gouv.fr/plan"
                        label={messagesAccessibilite.PAGE_PLAN_SITE}
                    />
                </li>
                <li>
                    <Anchor
                        target="_blank"
                        href="https://www.inscription-concours.interieur.gouv.fr/contact"
                        label={messagesAccessibilite.PAGE_CONTACT}
                    />
                </li>
                <li>
                    <Anchor
                        target="_blank"
                        href="https://www.inscription-concours.interieur.gouv.fr/"
                        label={messagesAccessibilite.PAGE_RECUP_MDP}
                    />
                </li>
            </ul>

            {/* CONTACT ET VOIES DE RECOURS */}

            <Heading level={2}>
                {messagesAccessibilite.RETOUR_CONTACT_TITLE}
            </Heading>
            <Paragraph fill margin={"none"}>
                {messagesAccessibilite.RETOUR_CONTACT_BODY_1}
            </Paragraph>
            <ul>
                <li>
                    {messagesAccessibilite.RETOUR_CONTACT_BODY_2}
                    <RoutedAnchor
                        to={paths.CONTACT}
                        label={messagesAccessibilite.RETOUR_CONTACT_LINK}
                    />
                </li>
            </ul>

            <Heading level={2}>
                {messagesAccessibilite.VOIES_RECOURS_TITLE}
            </Heading>
            <Paragraph margin={"none"}fill>
                {messagesAccessibilite.VOIES_RECOURS_BODY_1}
            </Paragraph>
            <Paragraph margin={"none"} fill>
                {messagesAccessibilite.VOIES_RECOURS_BODY_2}
            </Paragraph>
            <ul>
                <li>
                    <Anchor
                        href="https://formulaire.defenseurdesdroits.fr/formulaire_saisine/"
                        label={messagesAccessibilite.VOIES_RECOURS_BODY_3}
                    />
                </li>
                <li>
                    <Anchor
                        href="https://www.defenseurdesdroits.fr/saisir/delegues"
                        label={messagesAccessibilite.VOIES_RECOURS_BODY_4}
                    />
                </li>
                <li>{messagesAccessibilite.VOIES_RECOURS_BODY_5}</li>
                <li>{messagesAccessibilite.VOIES_RECOURS_BODY_6}</li>
            </ul>
        </Box>
    );
};
