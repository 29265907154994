import { useEffect } from "react";
import { Box, Main } from "grommet";

import { messagesAccueil } from "../messages/messagesAccueil";
import { HeaderSicmi } from "../common/HeaderSicmi";
import { FooterSicmi } from "../common/FooterSicmi";
import { Recherche } from "./Recherche";
import { Decouvrir } from "./Decouvrir";
import { AccueilModal } from "./AccueilModal";
import { useResponsive } from "../responsive";
import { tag } from '../common/ATInternetService.js';
import { store } from "../auth/store";
import { messagesCommon } from "../messages/messagesCommon";

export const Index = () => {
    const smallTheme = {
        fill: undefined,
    };

    const regularTheme = {
        fill: true,
    };

    useEffect(() => {
        document.title = messagesCommon.ACCUEIL + " – " + messagesCommon.MAIN_PAGE_TITLE;
        if (typeof window["EA_push"] === "function") {
            // @ts-ignore: Obligatoire pour pouvoir ajouter les tags Eulerian sans faire crasher la compilation
            EA_push([
                "path", "/sicmi",
                "pagelabel", "Accueil",
              ]);
        }
        
        if(!store.connected){
            tag.sendPage({
                name: 'Accueil',
                level1: 'Accueil'
            });
        }
    }, []);

    const responsiveTheme = useResponsive(smallTheme, regularTheme);
    
    return (
        <Box fill={responsiveTheme.fill}>
            <HeaderSicmi title={messagesAccueil.PAGE_TITLE} />

            <Main role="main">
                <AccueilModal />
                <Recherche />
                <Box fill={responsiveTheme.fill} pad={{ bottom: "xlarge" }}>
                    <Decouvrir />
                </Box>
            </Main>

            <FooterSicmi />
        </Box>
    );
};
